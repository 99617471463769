// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-health-packages-js": () => import("./../../../src/pages/healthPackages.js" /* webpackChunkName: "component---src-pages-health-packages-js" */),
  "component---src-pages-holiday-packages-js": () => import("./../../../src/pages/holidayPackages.js" /* webpackChunkName: "component---src-pages-holiday-packages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-tourism-js": () => import("./../../../src/pages/medicalTourism.js" /* webpackChunkName: "component---src-pages-medical-tourism-js" */),
  "component---src-pages-upcoming-page-js": () => import("./../../../src/pages/upcomingPage.js" /* webpackChunkName: "component---src-pages-upcoming-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-holiday-packages-js": () => import("./../../../src/templates/holidayPackages.js" /* webpackChunkName: "component---src-templates-holiday-packages-js" */),
  "component---src-templates-packages-js": () => import("./../../../src/templates/packages.js" /* webpackChunkName: "component---src-templates-packages-js" */)
}

